<template>
  <transition name="lsg-modal">
    <div class="lsg-overlay-mask">
      <div class="lsg-overlay-wrapper">
        <div class="lsg-overlay-container">
          <slot></slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'LsgOverlay'
}
</script>

<style lang="stylus">
.lsg-overlay
  &-mask
    position fixed
    z-index 99999
    top 0
    left 0
    width 100%
    height 100%
    background-color rgba(0, 0, 0, .8)
    display table
    transition opacity .3s ease

  &-wrapper
    display table-cell
    vertical-align middle

  &-container
    margin 0px auto
    padding 20px 30px
    transition all .3s ease
    font-family font_regular
    text-align center
    color $white
</style>
